import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import("@/pages/dashboard.vue"),
        meta: { title: 'Dashboard' }
    },
    {
        path: '/dashboard',
        name: 'dashboard2',
        component: () => import("@/pages/dashboard.vue"),
        meta: { title: 'Dashboard' }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login.vue'),
        meta: { title: 'Login' }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/pages/register.vue'),
        meta: { title: 'Register' }
    },
    {
        path: "/register/verify",
        name: "waitVerify",
        component: () => import("@/pages/register/verify.vue"),
        meta: { title: 'Verify' }
    },
    {
        path: "/admin",
        name: "indexAdmin",
        component: () => import("@/pages/admin/index.vue"),
        meta: { title: 'Admin' }
    },
    {
        path: "/admin/accounts",
        name: "adminAccounts",
        component: () => import("@/pages/admin/accounts.vue"),
        meta: { title: 'Accounts' }
    },
    {
        path: "/admin/limits",
        name: "adminLimits",
        component: () => import("@/pages/admin/limits.vue"),
        meta: { title: 'Account Limitations' }
    },
    {
        path: "/admin/users",
        name: "adminUsers",
        component: () => import("@/pages/admin/users.vue"),
        meta: { title: 'Users' }
    },
    {
        path: "/admin/verify",
        name: "adminVerify",
        component: () => import("@/pages/admin/verify.vue"),
        meta: { title: 'Verify' }
    },
    {
        path: "/admin/usage",
        name: "adminUsage",
        component: () => import("@/pages/admin/usage.vue"),
        meta: { title: 'Usage' }
    },
    {
        path: "/admin/settings",
        name: "adminSettings",
        component: () => import("@/pages/admin/settings.vue"),
        meta: { title: 'Settings' }
    },
    {
        path: "/reset-password",
        name: "resetPassword",
        component: () => import("@/pages/reset-password.vue"),
        meta: { title: 'Reset Password' }
    },
    {
        path: "/inventory/search",
        name: "inventorySearch",
        component: () => import("@/pages/inventory/search.vue"),
        meta: { title: 'Inventory search' }
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("@/pages/profile.vue"),
        meta: { title: 'Profile' }
    },
    {
        path: "/searches",
        name: "savedSearches",
        component: () => import("@/pages/saved-searches.vue"),
        meta: { title: 'Saved searches' }
    },
    {
        path: "/cart",
        name: "cartSummary",
        component: () => import("@/pages/cart.vue"),
        meta: { title: 'Cart' }
    },
    {
        path: "/carts",
        name: "carts",
        component: () => import("@/pages/carts.vue"),
        meta: { title: 'Carts' }
    },
    {
        path: "/orders",
        name: "orders",
        component: () => import("@/pages/orders/index.vue"),
        meta: { title: 'Orders' }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.title)
        document.title = to.meta.title.toString() + " - Midwest Metals";
    else
        document.title = "Midwest Metals";

    if (!store.getters.isLoggedIn && to.name != "login" && to.name != "register" && to.name != "resetPassword" && to.name != "waitVerify") {
        router.push("/login");
        next();
        return;
    }
    else {
        next();
    }
});

export default router
