//Limit the array by `limitNum`
//Array must be an array of Objects, use `prop` to define which property should be limited.
//Items will be kept in the order they are defined up to the point they have reached the limit.
//limitNum is (inclusive)
Array.prototype.limitBy = function (prop, limitNum) {
    if (!(typeof limitNum === 'number' || limitNum instanceof Number)) {
        try {
            limitNum = parseInt(limitNum);
        } catch { return this; }
    }

    if (this.length > 0) {
        if (typeof prop === 'string' || prop instanceof String) {
            let index = 0;
            let sum = 0;
            for (let i = 0; i < this.length; i++) {
                sum += this[i][prop];
                if (sum > limitNum) {
                    index = i;
                    break;
                }
            }
            if (index == 0) index = this.length;
            return this.slice(0, index);
        }

        return this;
    }
    else return this;
}
