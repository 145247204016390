<template>
    <nav class="navbar is-dark" role="navigation" aria-label="nav">
        <div class="container">
            <div class="navbar-brand">
                <router-link class="navbar-item" to="/">
                    <img src="@/assets/logo.png" width="50" height="50" class="logo" />
                    <span class="logo-text">Midwest Metals</span>
                </router-link>

                <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarContent">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="navbarContent" class="navbar-menu" v-if="$store.getters.isLoggedIn">
                <div class="navbar-start" style="flex-grow: 1; justify-content: center;">
                    <router-link class="navbar-item" to="/inventory/search">
                        Inventory
                    </router-link>
                    <router-link class="navbar-item" to="/orders">
                        Orders
                    </router-link>
                    <router-link class="navbar-item" to="/searches">
                        Searches
                    </router-link>
                    <router-link class="navbar-item" to="/cart">
                        Cart
                    </router-link>
                    <router-link v-if="$store.getters.isAdmin" class="navbar-item" to="/admin">
                        Admin
                    </router-link>

                </div>
            </div>
            <div class="navbar-end is-hidden-touch" v-if="$store.getters.isLoggedIn">
                <a class="navbar-item" @click="logout">Logout</a>
                <div class="navbar-item">
                    <div>
                        <div>
                            {{ $store.getters.accountRep?.Name }}
                        </div>
                        <div>
                            <a class="nav-link" :href="'mailto://' + $store.getters.accountRep?.Email">{{ $store.getters.accountRep.Email }}</a>
                        </div>
                        <div>
                            <a class="nav-link" :href="'tel://' + NormalizePhoneNumber($store.getters.accountRep.PhoneNumber)">{{ DisplayPhoneNumber($store.getters.accountRep.PhoneNumber) }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
    import store from "@/store";
    import { useRouter } from "vue-router";

    export default {
        setup(props, context) {
            const router = useRouter();

            const logout = () => {
                store.dispatch("logout");
                router.push("/login");
            };

            const NormalizePhoneNumber = (phoneNumber) => {
                if (!phoneNumber) return "";
                return phoneNumber.replace(/\D/g, '');
            }

            const DisplayPhoneNumber = (phoneNumber) => {
                if (!phoneNumber) return "";
                return phoneNumber.replace(/\+1(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
            }

            return { logout, NormalizePhoneNumber, DisplayPhoneNumber }
        }
    }

    document.addEventListener('DOMContentLoaded', () => {

        // Get all "navbar-burger" elements
        const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

        // Add a click event on each of them
        $navbarBurgers.forEach(el => {
            el.addEventListener('click', () => {

                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');

            });
        });

    });
</script>
<style>
    .logo {
        /* filter: invert(1); */
        max-height: none !important;
        transition: all linear 0.1s;
        margin-right: 12px;
    }

    .logo-text {
        font-size: 14pt;
        font-weight: bold;
        margin-left: 6px;
    }

    .nav-link {
        color: white;
    }

    .nav-link:hover {
        color: white;
        text-decoration: underline;
    }

    @media only screen and (max-width:1023px) {
        .logo {
            width: 30px;
            height: 30px;
        }
    }
</style>
