import { createStore } from 'vuex'
import Axios from 'axios';

export default createStore({
    state: {
        token: localStorage.getItem("AUTH_TOKEN"),
        refreshToken: localStorage.getItem("REFRESH_TOKEN"),
        user: JSON.parse(localStorage.getItem("USER") || "{}"),
        account: JSON.parse(localStorage.getItem("ACCOUNT") || "{}"),
        accountRep: JSON.parse(localStorage.getItem("ACCOUNTREP") || "{}")
    },
    mutations: {
        SET_USER(state, params) {
            if (params) {
                state.token = params.Token;
                state.refreshToken = params.RefreshToken;
                state.user = params;

                localStorage.setItem("AUTH_TOKEN", params.Token);
                localStorage.setItem("REFRESH_TOKEN", params.RefreshToken);
                localStorage.setItem("USER", JSON.stringify(params));
            }
            else {
                state.token = null;
                state.refreshToken = null;
                state.user = {};

                localStorage.removeItem("AUTH_TOKEN");
                localStorage.removeItem("REFRESH_TOKEN");
                localStorage.removeItem("USER");
            }
        },
        SET_ACCOUNT(state, params) {
            state.account = params;
            if (params) {
                state.account = params;
                localStorage.setItem("ACCOUNT", JSON.stringify(params));
            }
            else {
                state.account = {};
                localStorage.removeItem("ACCOUNT");
            }
        },
        SET_ACCOUNTREP(state, params) {
            state.accountRep = params;
            if (params) {
                state.accountRep = params;
                localStorage.setItem("ACCOUNTREP", JSON.stringify(params));
            }
            else {
                state.accountRep = {};
                localStorage.removeItem("ACCOUNTREP");
            }
        }
    },
    actions: {
        login: ({ commit, state }, params) => {
            commit("SET_USER", params);
            if (params) {
                Axios.interceptors.request.use(config => { config.headers["Authorization"] = "Bearer " + params.Token; return config; });
            }
        },
        logout: ({ commit, state }) => {
            commit("SET_USER", null);
            commit("SET_ACCOUNT", null);
            commit("SET_ACCOUNTREP", null);
        },
        setAccount: ({ commit, state }, params) => {
            commit("SET_ACCOUNT", params);
        },
        setAccountRep: ({ commit, state }, params) => {
            commit("SET_ACCOUNTREP", params);
        }
    },
    modules: {
    },
    getters: {
        user: state => {
            return state.user;
        },
        account: state => {
            return state.account;
        },
        accountRep: state => {
            return state.accountRep;
        },
        isLoggedIn: state => {
            return !!state.token;
        },
        isAdmin: state => {
            return state.user?.Roles?.some(r => r == 4);
        }
    }
})
