import { App, getCurrentInstance } from "vue";
import confirmModal from "@/components/modals/confirm-modal.vue";

const Confirm = {
    install(app: App, options = {}) {
        app.provide("$confirm", (opt) => {
            const baseOpt = {
                parent: getCurrentInstance(),
                component: confirmModal,
                trapFocus: true,
                events: {
                    onCancel: opt.onCancel,
                    onConfirm: opt.onConfirm
                }
            };
            app.config.globalProperties.$oruga.modal.open({
                ...baseOpt,
                props: opt
            });
        });
    }
}

export default Confirm;
