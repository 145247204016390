<template>
	<div class="modal-card login" style="width: auto; min-width: 680px">
		<header class="modal-card-head">
			<p class="modal-card-title">{{ title }}</p>
		</header>
		<section class="modal-card-body">
			<div v-html="message"></div>
		</section>
		<footer class="modal-card-foot">
			<o-button @click="Confirm" variant="primary">{{ confirmText }}</o-button>
			<o-button variant="light" @click="Cancel">{{ cancelText }}</o-button>
		</footer>
	</div>
</template>
<script>
	import { ref, computed, inject, onMounted } from "vue";

	export default {
		props: {
			title: {
				type: String,
				default: ""
			},
			message: {
				type: String,
				default: ""
			},
			cancelText: {
				type: String,
				default: "Cancel"
			},
			confirmText: {
				type: String,
				default: "Continue"
			}
		},
		emits: ["onCancel", "onConfirm", "close"],
		setup(props, context) {
			const Cancel = () => {
				context.emit("onCancel");
				context.emit("close");
			};

			const Confirm = () => {
				context.emit("onConfirm");
				context.emit("close");
			};

			return {
				Cancel,
				Confirm
			};
		}
	};
</script>
