import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Axios from 'axios';
import Oruga from '@oruga-ui/oruga-next';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@fortawesome/fontawesome-pro/css/all.css';
import '@fortawesome/fontawesome-pro/js/all.js';
import momentjs from 'moment';
//import braintree from 'braintree-web';
import Components from "@/components/index";
import Services from "@/services/index";
import "@/services/raygun";
import { bulmaConfig } from "@oruga-ui/theme-bulma";
import '@oruga-ui/theme-bulma/dist/bulma.css';
import '@/assets/css/global.scss';
import '@/prototypes.js';

Axios.defaults.baseURL = process.env.VUE_APP_API;
Axios.interceptors.request.use(config => { config.headers["Authorization"] = "Bearer " + localStorage.getItem("AUTH_TOKEN"); return config; });

const app = createApp(App);
app
    .component("loading", Components.loading)
    .component("fa-icon", FontAwesomeIcon);
app
    .provide("$http", Axios)
    .provide("$moment", momentjs)
    //.provide("$braintree", braintree);
app
    .use(Oruga, {
        iconPack: 'far',
        iconComponent: 'fa-icon',
        ...bulmaConfig
    })
    .use(Services.debouncer)
    .use(Services.confirmModal)
    .use(Services.notification.Success)
    .use(Services.notification.Error)
    .use(Services.notification.Warn)
    .use(store)
    .use(router);
app
    .provide("$store", app.config.globalProperties.$store)
    .provide("$oruga", app.config.globalProperties.$oruga);

app.mount('#app');

//How to customize Oruga: https://github.com/oruga-ui/demo-multiframework/blob/master/src/views/Bulma.vue
