import notification from "@/services/notify";
import { raygun } from "@/services/raygun";
import { refreshToken } from "@/services/refresh";
import httpGetCached from "@/services/httpGetCached";
import mobileCheck from "@/services/mobileCheck";
import debouncer from "@/services/debouncer";
import confirmModal from "@/services/confirm";

export default {
    notification,
    raygun,
    refreshToken,
    httpGetCached,
    mobileCheck,
    debouncer,
    confirmModal
}
