/*
    How to use it;
    const debouncer = inject("$debouncer");
    const debounceFn = debouncer(MyFunc, 500);
    debounceFn(); //Invoke it; Only the last invocation is processed after the timespan of 500ms
*/
import { App, getCurrentInstance } from "vue";

const _debounce = function (func, delay) {
    let timeoutId;
    return async function (...args) {
        clearTimeout(timeoutId);

        return new Promise((resolve) => {
            timeoutId = setTimeout(async () => {
                //@ts-ignore
                await func.apply(this, args);
                //@ts-ignore
                resolve();
            }, delay);
        });
    };
};

const Debouncer = {
    install(app: App, options = {}) {
        app.provide("$debouncer", (func, timeout) => {
            return _debounce(func, timeout);
        });
    }
}

export default Debouncer;
