<template>
    <navbar></navbar>
    <div>
        <router-view />
    </div>
</template>

<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    import store from "@/store";
    import navbar from "@/components/nav.vue";
    import { useRouter } from "vue-router";

    export default {
        components: {
            navbar
        },
        setup() {
            const http = inject("$http");
            const router = useRouter();

            var refreshPromises = [];
            var refreshing = false;
            http.interceptors.response.use(resp => {
                return resp;
            }, async (err) => {
                if (err.response.status >= 401 && err.response.headers["token-expired"] == "true" && !refreshing) {
                    refreshing = true;
                    return await new Promise((resolve, reject) => {
                        http.post("/api/user/refresh", {})
                            .then(async (r) => {
                                if (r) {
                                    store.dispatch("login", r.data);
                                    refreshing = false;
                                    resolve(http.request(err.config));
                                    refreshPromises = refreshPromises.map(async (config) => {
                                        return await http.request(config);
                                    });
                                }
                                else {
                                    resolve(err.config);
                                }
                            })
                            .catch(e => {
                                console.error("Failed to refresh.");
                                store.dispatch("logout");
                                reject(e);
                            });
                    });
                }
                else if (refreshing) {
                    refreshPromises.push(err.config);
                }
                else if (err.response.status == 401) {
                    router.push({ name: "login" });
                }
                else {
                    return Promise.reject(err);
                }
            });


            return {


            }
        }
    }
</script>
<style>
    .side-margin {
        margin: 0 14px;
    }
</style>
